const initBreadCrumbs = {
  '/partner-prices': [
    { key: 'manage_price_list' },
    { key: 'partner_list' },
    { key: 'partner_price_list' }
  ],

  '/guest-prices': [{ key: 'manage_price_list' }, { key: 'guest_price_list' }],

  // '/orders': [{ key: 'order_management' }, { key: 'order_list' }],
  '/order-create': [{ key: 'create_order' }],
  '/order-create-multiple': [{ key: 'create_multiple_order' }],
  '/order-create-multi-box': [{ key: 'create_multi_box_order' }],
  '/account': [{ key: 'account_management' }, { key: 'account_info' }]
}

export class DataBreadCrumbUtils {
  static getBreadCrumbByPathActive(path) {
    return initBreadCrumbs[path]
  }
}
