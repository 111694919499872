<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    color="primary"
    :expand-on-hover="expandOnHover"
    mobile-breakpoint="960"
    :mini-variant="mini"
    mini-variant-width="100"
    app
    width="300"
    v-bind="$attrs"
  >
    <v-layout justify-center column class="mt-8">
      <div class="d-flex align-center">
        <v-layout justify-center align-center class="ml-8">
          <h2 class="logo" />
        </v-layout>
        <div>
          <v-btn v-if="!mini" fab icon dark x-small class="btn-menu" @click="mini = !mini">
            <v-icon>mdi-menu-left</v-icon>
          </v-btn>
          <v-btn v-else fab icon dark x-small class="btn-menu" @click="mini = !mini">
            <v-icon>mdi-menu-right</v-icon>
          </v-btn>
        </div>
      </div>
      <v-layout v-if="!mini" justify-center class="mt-3">
        <span class="white--text">
          {{ $t('post_offices') }}
        </span>
      </v-layout>
      <v-layout v-if="!mini" justify-center class="mt-2">
        <span class="white--text">
          {{ currentPoName }}
        </span>
      </v-layout>
      <v-layout v-if="showBtnCreate === true" justify-center class="mt-3">
        <v-btn v-if="!mini" class="ma-2" outlined color="white" @click="onShowDialogCreateOrderOption">
          <v-icon>mdi-plus</v-icon>
          {{ $t('create_order') }}
        </v-btn>
        <v-btn v-else outlined class="mr-2" small fab color="white" @click="onShowDialogCreateOrderOption">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-layout>
    </v-layout>

    <v-list v-for="(menu, i) in items" :key="i">
      <v-list-item
        v-if="!menu.child"
        v-show="menu.roleParant"
        link
        :to="menu.to"
        class="white--text"
        @click="goMenu(menu)"
      >
        <v-list-item-icon class="mr-3">
          <v-icon v-text="menu.icon" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="white--text" v-text="menu.title" />
        </v-list-item-content>
      </v-list-item>
      <v-list-group v-else v-model="menu.active" no-action class="white--text">
        <template v-slot:activator>
          <v-list-item-icon class="mr-3">
            <v-icon v-text="menu.icon" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text">{{
              menu.title
            }}</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="(item, key) in menu.child"
          v-show="item.role"
          :key="key"
          :to="item.to"
          link
          class="white--text"
          @click="goMenu(item)"
        >
          <v-list-item-title class="white--text" v-text="item.title" />
          <v-list-item-icon>
            <v-icon v-text="item.icon" />
          </v-list-item-icon>
        </v-list-item>
      </v-list-group>
    </v-list>

    <dialog-create-order-options
      :internal-value="isShowCreateOrderOptions"
      :active-package="activePackage"
      :active-packages="activePackages"
      :active-multi-box="activeMultiBox"
      @on-close-dialog="onCloseDialog"
      @go-order-create="goOrderCreate"
      @go-order-create-multiple="goOrderCreateMultiple"
      @go-order-create-multi-box="goOrderCreateMultiBox"
    />
  </v-navigation-drawer>
</template>

<script>
// Utilities
import DialogCreateOrderOptions from "@/components/dialog/DialogCreateOrderOptions.vue";
import { mapState, mapActions } from "vuex";
import routePaths from "@/router/routePaths";
import { DataBreadCrumbUtils } from "@/helpers/dataBreadCrumbUtils";
import { StringUtils } from '@/helpers/stringUtils'
import { UserService } from '@/services/userService'
import { StorageService } from '@/services/storageService'
import constants from '@/constants'

export default {
  name: "DashboardCoreDrawer",
  components: {
    DialogCreateOrderOptions,
  },
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [
        {
          icon: "mdi-warehouse",
          roleParant: true,
          roleParantPoTwo: false,
          title: this.$t("manage_post_offices"),
          child: [
            {
              icon: "",
              role: true,
              title: this.$t("list_post_offices"),
              to: routePaths.POST_OFFICE_LIST,
            },
            {
              icon: "",
              role: true,
              title: this.$t("list_admin"),
              to: routePaths.LIST_ADMIN,
              childViewPoTwo: false
            },
          ],
          active: false,
          viewOfSale: false,
          viewOfCustomer: false,
        },
        {
          icon: "mdi-account-box-outline",
          roleParant: true,
          roleParantPoTwo: true,
          title: this.$t("customer_management"),
          child: [
            {
              icon: "",
              role: true,
              title: this.$t("list_customers"),
              to: routePaths.CUSTOMERS,
              childViewOfSale: true,
              childViewPoTwo: true
            },
            {
              icon: "",
              role: true,
              title: this.$t("activation_waiting_list"),
              to: routePaths.CUSTOMERS_ACTIVATION_WAITING,
              childViewOfSale: false,
              childViewPoTwo: true
            },
          ],
          active: false,
          viewOfSale: true,
          viewOfCustomer: false,
        },
        {
          icon: "mdi-view-list-outline",
          roleParant: true,
          roleParantPoTwo: true,
          title: this.$t("manage_price_list"),
          child: [
            {
              icon: "",
              role: true,
              title: this.$t("partner_price_list"),
              to: routePaths.PARTNER_LIST,
              childViewPoTwo: false
            },
            {
              icon: "",
              role: true,
              title: this.$t("shop_price_list"),
              to: routePaths.SHOP_PRICE_LIST,
              childViewPoTwo: true
            },
            {
              icon: "",
              role: true,
              title: this.$t("price_list_po_one"),
              to: routePaths.PRICE_LIST_PO_PUBLIC,
              childViewPoTwo: true
            },
          ],
          active: false,
          viewOfSale: false,
          viewOfCustomer: false,
        },
        {
          icon: "mdi-account-multiple",
          roleParant: true,
          roleParantPoTwo: true,
          title: this.$t("employee_management"),
          child: [
            {
              icon: "",
              role: true,
              title: this.$t("employee_list"),
              to: routePaths.EMPLOYEES,
              childViewPoTwo: true
            },
          ],
          active: false,
          viewOfSale: false,
          viewOfCustomer: false,
        },
        {
          icon: "mdi-archive-outline",
          roleParant: true,
          roleParantPoTwo: true,
          title: this.$t("order_management"),
          child: [
            {
              icon: "",
              role: true,
              title: this.$t("order_list"),
              to: routePaths.ORDERS,
              childViewOfSale: true,
            },
            {
              icon: "",
              role: true,
              title: this.$t("order_list_process"),
              to: routePaths.LIST_ORDER_PROCESS,
              childViewOfSale: true,
            },
            {
              icon: "",
              role: true,
              title: this.$t("order_list_partnal"),
              to: routePaths.LIST_ORDER_ONE_PART,
              childViewOfSale: true,
            },
          ],
          active: false,
          viewOfSale: true,
          viewOfCustomer: true,
        },
        {
          icon: "mdi-file-chart-outline",
          roleParant: true,
          roleParantPoTwo: true,
          title: this.$t("statistical"),
          child: [
            {
              icon: "",
              role: true,
              title: this.$t("statis_order"),
              to: routePaths.LIST_STATIS_ORDER,
              childViewOfSale: true,
              childViewPoTwo: true
            },
            {
              icon: "",
              role: true,
              title: this.$t("static_revenue"),
              to: routePaths.LIST_STATIS_REVENUE,
              childViewOfSale: false,
              childViewPoTwo: true
            },
          ],
          active: false,
          viewOfSale: true,
          viewOfCustomer: false,
        },
      ],
      currentUser: JSON.parse(localStorage.getItem("CURRENT_USER")),
      isShowCreateOrderOptions: false,
      activePackage: false,
      activePackages: false,
      activeMultiBox: false,
      mini: false,
      currentRole: JSON.parse(localStorage.getItem("CURRENT_USER")).roles,
      currentPoId: JSON.parse(localStorage.getItem("CURRENT_USER")).poId,
      currentPoName: "",
      showBtnCreate: false
    };
  },
  computed: {
    ...mapState("layout", []),
    drawer: {
      get() {
        return this.$store.state.layout.drawer;
      },
      set(val) {
        this.setDrawer(val);
      },
    },
    checkRoleCreate() {
      return this.currentRole.includes("ROLE_ACCOUNTANT")
    },
  },
  created() {
    this.fillActiveMenu();
    this.getUserInfo()
    this.checkRoleMenu();
    this.checkBtnCreate()
  },
  methods: {
    ...mapActions("layout", ["setDrawer", "setOrderCreate", "setBreadcrumbs"]),
    fillActiveMenu() {
      const pathActive = this.$router.currentRoute.path;
      this.bindActiveMenu(pathActive);
    },
    async getUserInfo() {
      const pars = {
        usersId: StorageService.getUserId()
      }
      const { status, data } = await UserService.employeeGetInfo(pars)
      if (status === constants.statusCode.ok && data) {
        this.currentPoName = data.poName
      } else {
        if (StorageService.checkResponseExpried(status, data)) {
          this.showConfirmTokenExpried = true
        } else {
          this.toggleDialogNoti({ state: true, msg: data.message })
          setTimeout(() => {
            this.toggleDialogNoti()
          }, constants.timeOut)
        }
      }
    },
    checkBtnCreate() {
      if (this.currentRole.includes("ROLE_ACCOUNTANT")) {
        this.showBtnCreate = false
      } else {
        this.showBtnCreate = true
      }
      if (this.currentRole.includes("ROLE_ACCOUNTANT") && this.currentUser.roles.includes("ROLE_SALE")) {
        this.showBtnCreate = true
      }
    },
    checkRoleMenu() {
      if (this.currentUser.roles.includes("ROLE_ADMIN")) {
        this.items = this.items.filter((item) => item.roleParant);
        if (this.currentPoId !== 2) {
          this.items = this.items.filter((it) => it.roleParantPoTwo)
          this.items.forEach((item) => {
            item.child = item.child.filter((it) => it.childViewPoTwo || it.childViewOfSale)
          })
        }
      } else if (
        this.currentUser.roles.length === 1 &&
        this.currentUser.roles.includes("ROLE_SALE") || (this.currentUser.roles.includes("ROLE_SALE") && this.currentRole.includes("ROLE_ACCOUNTANT"))
      ) {
        this.items = this.items.filter((item) => item.viewOfSale);
        this.items.forEach((items) => {
          items.child = items.child.filter((item) => item.childViewOfSale)
        });
      } else if ((this.currentRole.includes("ROLE_CUSTOMER_CARE") || this.currentRole.includes("ROLE_ACCOUNTANT")) && !this.currentRole.includes("ROLE_ADMIN")) {
        this.items = this.items.filter(item => item.viewOfCustomer)
      } else if (this.currentPoId !== 2 && this.currentUser.roles.includes("ROLE_ADMIN")) {
        this.items = this.items.filter((it) => it.roleParantPoTwo)
        this.items.forEach((item) => {
          item.child = item.child.filter((it) => it.childViewPoTwo || it.childViewOfSale)
        })
      }
    },
    goMenu(menu) {
      this.bindActiveMenu(menu.to);
      if (this.$router.currentRoute.path === menu.to) {
        this.$router.go();
      }
    },
    bindActiveMenu(pathActive) {
      this.items.forEach((item) => {
        item.active = false;

        if (item.child) {
          item.child.forEach((value) => {
            if (value.to === pathActive) {
              item.active = true;
            }
          });
        }

        if (typeof item.to !== undefined && item.to === pathActive) {
          item.active = true;
        }
      });

      this.addBreadCrumbs(pathActive);
    },
    onShowDialogCreateOrderOption() {
      this.isShowCreateOrderOptions = true
    },
    onCloseDialog() {
      this.isShowCreateOrderOptions = false;
    },
    goOrderCreate() {
      this.activePackage = true
      this.activePackages = false
      this.activeMultiBox = false
      this.isShowCreateOrderOptions = false
      const pathName = StringUtils.getPathNameByPath(this.$router.currentRoute.path)
      if ((pathName !== routePaths.ORDER_CREATE)) {
        this.addBreadCrumbs(routePaths.ORDER_CREATE)
        this.setOrderCreate(true)
        this.$router.push(routePaths.ORDER_CREATE)
      }
    },
    goOrderCreateMultiple() {
      this.activePackage = false
      this.activePackages = true
      this.activeMultiBox = false
      this.isShowCreateOrderOptions = false
      const pathName = StringUtils.getPathNameByPath(this.$router.currentRoute.path)
      if ((pathName !== routePaths.ORDER_CREATE_MULTIPLE)) {
        this.addBreadCrumbs(routePaths.ORDER_CREATE_MULTIPLE)
        this.setOrderCreate(true)
        this.$router.push(routePaths.ORDER_CREATE_MULTIPLE)
      }
    },
    goOrderCreateMultiBox() {
      this.activePackage = false
      this.activePackages = false
      this.activeMultiBox = true
      this.isShowCreateOrderOptions = false
      const pathName = StringUtils.getPathNameByPath(this.$router.currentRoute.path)
      if ((pathName !== routePaths.ORDER_CREATE_MULTI_BOX)) {
        this.addBreadCrumbs(routePaths.ORDER_CREATE_MULTI_BOX)
        this.setOrderCreate(true)
        this.$router.push(routePaths.ORDER_CREATE_MULTI_BOX)
      }
    },
    addBreadCrumbs(pathActive) {
      const breadCrumbs =
        DataBreadCrumbUtils.getBreadCrumbByPathActive(pathActive);
      if (pathActive === routePaths.PARTNER_ACCOUNTS) {
        const partnerName = this.$route.query.name;
        const itemLast = breadCrumbs ? breadCrumbs.slice(-1) : '';
        if (itemLast) {
          itemLast[0]["name"] = partnerName;
        }
      }
      this.setBreadcrumbs(breadCrumbs);
    },
    getColorMenu(menu) {
      return "white";
    },
  },
};
</script>
